<template>
  <MainDialog :on-close="closeDialog" :show="show" :persistent="false">
    <v-card light>
      <v-card-title>
        <span class="headline"
        >{{ !printVariant ? "Create" : "Edit" }} Print Variant</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="printVariantCopy.name"
                label="Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="printVariantCopy.aspectRatio"
                label="AspectRatio"
                :items="aspectRatios"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <div>Portrait Mockup</div>
              <MockupSelector
                :aspect-ratio="printVariantCopy.aspectRatio"
                orientation="portrait"
                :selectedMockupId="printVariantCopy.mockupPortrait"
                @select="(id) => onSelectMockup(id, 'portrait')"
              />
            </v-col>
            <v-col cols="6">
              <div>Landscape Mockup</div>
              <MockupSelector
                :aspect-ratio="printVariantCopy.aspectRatio"
                orientation="landscape"
                :selectedMockupId="printVariantCopy.mockupLandscape"
                @select="(id) => onSelectMockup(id, 'landscape')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h4>Define required fields for fulfiller:</h4>
            </v-col>
          </v-row>
          <v-card
            class="fulfiller-card"
            cols="12"
            v-for="fulfillerKey in Object.keys(printVariantCopy?.fulfiller)"
            :key="fulfillerKey"
          >
            <v-card-title>{{ fulfillerKey }}</v-card-title>
            <v-card-subtitle
            >Fill in all required fields for the selected fulfiller. Check API
              to understand the requirements.
            </v-card-subtitle
            >
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  v-for="fieldKey in Object.keys(
                    requiredFieldsByFulfiller?.[fulfillerKey]
                  )"
                  :key="fieldKey"
                >
                  <div v-if="fieldKey === 'shippingCosts'">
                    {{fieldKey}}
                    <v-row>
                      <v-col v-for="countryCode in Object.keys(printVariantCopy?.fulfillRank)" :key="countryCode" cols="3">
                        <v-text-field :disabled="!printVariantCopy?.fulfillRank[countryCode].includes(fulfillerKey)" :rules="numberRules" :label="countryCode" v-model.number="printVariantCopy.fulfiller[fulfillerKey][fieldKey][countryCode]"/>
                      </v-col>
                    </v-row>
                  </div>
                  <v-text-field
                    v-else-if="
                      typeof requiredFieldsByFulfiller?.[fulfillerKey][
                        fieldKey
                      ] === 'string'
                    "
                    v-model="printVariantCopy.fulfiller[fulfillerKey][fieldKey]"
                    :label="fieldKey"
                  />
                  <v-text-field
                    v-else-if="
                      typeof requiredFieldsByFulfiller?.[fulfillerKey][
                        fieldKey
                      ] === 'number'
                    "
                    :rules="numberRules"
                    v-model.number="printVariantCopy.fulfiller[fulfillerKey][fieldKey]"
                    :label="fieldKey"
                  />
                  <div v-if="fieldKey === 'express'">
                    <v-checkbox
                      label="Express Available"
                      v-if="printVariantCopy.fulfiller[fulfillerKey][fieldKey]"
                      v-model="printVariantCopy.fulfiller[fulfillerKey][fieldKey].isAvailable"
                    ></v-checkbox>
                    <v-checkbox
                      label="Express Available"
                      v-else
                      v-model="printVariantCopy.fulfiller[fulfillerKey][fieldKey]"
                      :value="{
                        isAvailable: true,
                        costs: 0,
                        shipping: {}
                      }"
                    ></v-checkbox>
                    <div v-if="printVariantCopy.fulfiller[fulfillerKey]?.[fieldKey]?.isAvailable">
                      <v-text-field :rules="numberRules" label="Production Costs" v-model="printVariantCopy.fulfiller[fulfillerKey][fieldKey].costs"/>
                      Shipping Costs
                      <v-row>
                        <v-col v-for="countryCode in Object.keys(printVariantCopy?.fulfillRank)" :key="countryCode" cols="3">
                          <v-text-field :disabled="!printVariantCopy?.fulfillRank[countryCode].includes(fulfillerKey)" :rules="numberRules" :label="countryCode" v-model.number="printVariantCopy.fulfiller[fulfillerKey][fieldKey].shipping[countryCode]"/>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-row v-if="availableFulfiller.length">
            <v-col cols="9">
              <v-select
                :items="availableFulfiller"
                item-text="name"
                item-value="key"
                filled
                label="Choose fulfiller"
                v-model="selectedFulfiller"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-btn width="100%" color="primary" x-large @click="addFulfiller"
              >Add Fulfiller
              </v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h4>Choose fulfillment rank for countries:</h4>
            </v-col>
          </v-row>
          <v-card
            class="fulfiller-card"
            cols="12"
            v-for="countryCode in Object.keys(printVariantCopy?.fulfillRank)"
            :key="countryCode"
          >
            <v-card-title>{{ countryCode }}</v-card-title>
            <v-card-subtitle
            >Add the fulfiller in the order you want to fulfilled them by
              (first is default)
            </v-card-subtitle
            >
            <v-card-text>
              <v-row>
                <v-col
                  cols="3"
                  v-for="(fulfiller, index) in printVariantCopy?.fulfillRank[
                    countryCode
                  ]"
                  :key="fulfiller"
                  class="mr-3"
                >
                  <div class="fulfill-rank-item">
                    <div class="fulfill-rank-item__rank">{{ index + 1 }}.</div>
                    <div class="fulfill-rank-item__fulfiller">
                      {{ fulfiller }}
                    </div>
                    <div class="fulfill-rank-item__actions">
                      <v-icon
                        color="error"
                        @click="
                          () => deleteFulfillerForCountry(countryCode, index)
                        "
                      >mdi-delete
                      </v-icon
                      >
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row
                :key="availableFulfillerByCountryUpdateKey"
                v-if="getAvailableFulfillerByCountry(countryCode).length"
              >
                <v-col cols="9">
                  <v-select
                    :items="getAvailableFulfillerByCountry(countryCode)"
                    filled
                    label="Choose Fulfiller"
                    v-model="selectedFulfillerForCountry"
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-btn
                    color="primary"
                    width="100%"
                    x-large
                    @click="() => addFulfillerForCountry(countryCode)"
                  >Add Fulfiller
                  </v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-row v-if="availableCountries.length">
            <v-col cols="9">
              <v-select
                :items="availableCountries"
                filled
                label="Choose country"
                v-model="selectedCountry"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-btn
                width="100%"
                color="primary"
                x-large
                @click="addFulfillCountry"
              >Add Country
              </v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="isSaving"
          :loading="isSaving"
          color="primary"
          text
          @click="closeDialog"
        >Cancel
        </v-btn
        >
        <v-btn color="primary" x-large @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </MainDialog>
</template>

<script>
import MainDialog from "@/components/MainDialog";
import {mapActions} from "vuex";
import {countryCodes} from "@/constants";
import {availableAspectRatios} from "@/config";
import MockupSelector from "@/components/Settings/Mockups/MockupSelector";

const _ = require("lodash");

export default {
  name: "PrintVariantDialog",
  components: {MockupSelector, MainDialog},
  data() {
    return {
      isSaving: false,
      aspectRatios: availableAspectRatios,
      selectedFulfiller: "",
      selectedCountry: "",
      selectedFulfillerForCountry: "",
      availableFulfillerByCountryUpdateKey: 0,
      allFulfiller: [
        {
          key: "picanova",
          name: "Picanova",
          requiredFields: {
            costs: 0,
            shippingCosts: {},
            variant_code: "",
            options: JSON.stringify({
              1: 1,
              2: 6,
            }),
          },
        },
        {
          key: "myPoster",
          name: "MyPoster",
          requiredFields: {
            costs: 0,
            shippingCosts: {},
            material: "",
            options: JSON.stringify([]),
            width: 0,
            height: 0,
            express: {
              available: false,
              costs: 0,
              shippingCosts: {}
            }
          },
        },
        {
          key: "manual",
          name: "Manual Fulfillment",
          requiredFields: {
            costs: 0,
            shippingCosts: {},
            express: {
              available: false,
              costs: 0,
              shippingCosts: {}
            }
          },
        },
      ],
      defaultPrintVariant: {
        fulfiller: {},
        fulfillRank: {},
        name: "",
      },
      printVariantCopy: {},
      numberRules: [(value) => /^\d+(\.\d{2})?$/.test(value) || "Must be a number with up to 2 decimal places"],
    };
  },
  props: {
    show: {
      default: false,
    },
    onClose: {
      required: true,
    },
    printVariant: {
      type: Object,
      required: false,
    },
  },
  computed: {
    availableFulfiller() {
      return this.allFulfiller.filter(
        (fulfiller) => !this.printVariantCopy?.fulfiller?.[fulfiller.key]
      );
    },
    allCountries() {
      return Object.keys(countryCodes);
    },
    availableCountries() {
      return this.allCountries.filter(
        (country) => !this.printVariantCopy?.fulfillRank?.[country]
      );
    },
    requiredFieldsByFulfiller() {
      return this.allFulfiller.reduce((obj, item) => {
        obj[item.key] = item.requiredFields;
        return obj;
      }, {});
    }

  },
  watch: {
    printVariant: {
      deep: true,
      handler: function (newVal) {
        if (newVal) {
          this.printVariantCopy = _.cloneDeep(newVal);
        }
      },
    },
  },
  created() {
    this.printVariantCopy = _.cloneDeep(this.defaultPrintVariant);
  },
  methods: {
    ...mapActions(["addPrintVariant", "editPrintVariant"]),
    onSelectMockup(id, orientation) {
      console.log(id)

      if (orientation === 'landscape') {
        this.printVariantCopy = {
          ...this.printVariantCopy,
          mockupLandscape: id
        }
      } else {
        this.printVariantCopy = {
          ...this.printVariantCopy,
          mockupPortrait: id
        }
      }
    },
    async save() {
      this.isSaving = true;
      if (this.printVariant) {
        await this.editPrintVariant(this.printVariantCopy);
        this.isSaving = false;
        this.closeDialog();
      } else {
        await this.addPrintVariant(this.printVariantCopy);
        this.isSaving = false;
        this.closeDialog();
      }
    },
    addFulfiller() {
      const fulfiller = this.allFulfiller.find(
        (fulfiller) => fulfiller.key === this.selectedFulfiller
      );
      this.printVariantCopy = {
        ...this.printVariantCopy,
        fulfiller: {
          ...this.printVariantCopy.fulfiller,
          [fulfiller.key]: {
            ...fulfiller.requiredFields,
          },
        },
      };
      this.selectedFulfiller = "";
    },
    addFulfillCountry() {
      const countryCode = this.selectedCountry;
      this.printVariantCopy = {
        ...this.printVariantCopy,
        fulfillRank: {
          ...this.printVariantCopy.fulfillRank,
          [countryCode]: [],
        },
      };
    },
    addFulfillerForCountry(countryCode) {
      this.printVariantCopy = {
        ...this.printVariantCopy,
        fulfillRank: {
          ...this.printVariantCopy.fulfillRank,
          [countryCode]: [
            ...this.printVariantCopy.fulfillRank[countryCode],
            this.selectedFulfillerForCountry,
          ],
        },
      };
      this.selectedFulfillerForCountry = "";
      this.availableFulfillerByCountryUpdateKey++;
    },
    deleteFulfillerForCountry(countryCode, indexToDelete) {
      this.printVariantCopy = {
        ...this.printVariantCopy,
        fulfillRank: {
          ...this.printVariantCopy.fulfillRank,
          [countryCode]: [
            ...this.printVariantCopy.fulfillRank[countryCode].filter(
              (_, index) => index !== indexToDelete
            ),
          ],
        },
      };
      this.availableFulfillerByCountryUpdateKey++;
    },
    closeDialog() {
      this.onClose();
      this.printVariantCopy = _.cloneDeep(this.defaultPrintVariant);
    },
    getAvailableFulfillerByCountry(countryCode) {
      const fulfillerByCountry =
        this.printVariantCopy.fulfillRank?.[countryCode];
      return Object.keys(this.printVariantCopy?.fulfiller).filter(
        (fulfiller) => !fulfillerByCountry?.includes(fulfiller)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.fulfiller-card {
  border: #fff solid 2px;
  margin: 12px 0;
  width: 100%;
}

.fulfill-rank-item {
  display: flex;
  padding: 12px;
  background: #e7e7e7;
  color: #000000;
  border-radius: 12px;
  font-size: 24px;
  width: fit-content;

  &__actions {
    padding-left: 6px;
  }
}
</style>
