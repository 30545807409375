<template>
  <component
    :is="mailComponent"
    v-if="show && dataIsReady"
    :show="show && dataIsReady"
    :on-sent="onSent"
    :on-close="onClose"
    :receiver="receiver"
    :messages="messages"
    :shop-id="shopId"
    :country-code="countryCode"
    :wait-for-sent-success="waitForSentSuccess"
    :customer-message="customerMessage"
    :default-language="defaultLanguage"
    :auto-translate="autoTranslate"
    :order="order"
    :mail-category="mailCategory"
  />
</template>

<script>
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../fb";
import {
  getAllMailTemplates,
  getMailTemplatesImport,
  getMailTemplatesReview, getReviseMailTemplates,
  mailTemplateTypes,
} from "@/content/mails";
import {getMailDamagedPackage, getSupportMails} from "@/content/supportMails";
import MailDialogV2 from "@/components/MailDialogV2";
import MailDialog from "@/components/MailDialog";
export default {
  name: "OrderMailDialog",
  components: {MailDialogV2 },
  data() {
    return {
      receiver: "",
      countryCode: "",
      shopId: "",
      messages: [],
      dataIsReady: false,
      order: null
    };
  },
  props: {
    v2: {
      type: Boolean,
      default: false,
    },
    show: {
      default: false,
    },
    onClose: {
      required: true,
    },
    onSent: {
      required: false,
    },
    autoTranslate: {
      type: Boolean,
      default: false
    },
    orderId: {
      type: String,
      required: true,
    },
    mailTemplateType: {
      type: String,
    },
    mailCategory: {
      type: String,
      required: false,
    },
    waitForSentSuccess: {
      type: Boolean,
      default: true
    },
    customerMessage: {
      type: String,
      required: false,
    },
    defaultLanguage: {
      type: String,
      required: false,
    }
  },
  computed: {
    mailComponent() {
      return this.v2 ? MailDialogV2 : MailDialog
    }
  },
  created() {
    const orderDoc = doc(db, "orders", this.orderId);
    getDoc(orderDoc).then((orderSnap) => {
      const order = orderSnap.data();

      const customerName = order.customer.firstName;
      const orderName = order.orderName;
      const countryCode = order.countryCode;
      const email = order.customer.email

      this.receiver = email;
      this.countryCode = countryCode;
      this.shopId = order.shopId
      this.order = order

      switch (this.mailTemplateType) {
        case mailTemplateTypes.ALL:
          this.messages = getAllMailTemplates({
            customerName,
            orderName,
            countryCode,
            email
          });
          break;
        case mailTemplateTypes.IMPORT:
          this.messages = getMailTemplatesImport({
            customerName,
            orderName,
            countryCode,
          });
          break;
        case mailTemplateTypes.REVIEW:
          this.messages = getMailTemplatesReview({
            customerName,
            orderName,
            countryCode,
          });
          break;
        case mailTemplateTypes.REVISE:
          this.messages = getReviseMailTemplates({
            customerName,
            orderName,
            countryCode,
          });
          break;
        case mailTemplateTypes.SUPPORT_DAMAGE:
          this.messages = getMailDamagedPackage(order, this.defaultLanguage);
          break;
        case mailTemplateTypes.SUPPORT:
          this.messages = getSupportMails(order, this.defaultLanguage);
          break;
        default:
          this.messages = [];
      }

      this.dataIsReady = true;
    });
  },
};
</script>

<style scoped></style>
