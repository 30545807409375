<template>
  <MainDialog :show="show">
    <v-card light>
      <v-card-title>{{ addTemplate ? 'Create New Template' : 'Send Mail' }} </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <div class="messages-chip-container">
              <v-chip
                :disabled="addTemplate"
                v-for="template in templatesForCategory"
                :key="template.uid"
                @click="() => setTemplate(template)"
                :color="(selectedTemplate?.uid === template?.uid && (editTemplate || !addTemplate)) ? '#ccffc4' : '#fff'"
              >
                {{ template.name }}
                <v-icon class="ml-3" @click.stop="showEditTemplate" x-small v-if="selectedTemplate?.uid === template?.uid">mdi-pencil</v-icon>
              </v-chip>
              <v-chip @click="showAddNewTemplate" :color="addTemplate && !editTemplate ? '#ccffc4' : '#fff'">
                <v-icon small>mdi-plus</v-icon>
              </v-chip>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Receiver"
              outlined
              hide-details
              v-model="receiverMail"
            />
          </v-col>
          <v-col cols="9">
            <v-text-field
              hide-details
              label="Subject"
              outlined
              v-model="subjectValue"
            />
          </v-col>
          <v-col cols="12">
            <VueTrix
              class="wysiwyg-editor"
              style="color: black"
              v-model="messageValue"
              placeholder="Enter content"
              localStorage
            />
            <div v-if="showAvailableVariables" class="availableVariablesHelper">
              <div @click="() => selectVariable(key)" v-for="key in Object.keys(availableVariables)" :key="key">{{key}}</div>
            </div>
          </v-col>
          <v-col cols="12">
            <v-file-input
              v-if="!addTemplate"
              outlined
              @change.native="onFileChange"
              @click:clear="attachments = []"
              chips
              clearable
              label="Attachments"
              multiple
            />
            <v-text-field required outlined v-else label="Template Name" v-model="templateName" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <template v-if="!addTemplate">
          <v-btn text @click="onClose">Cancel</v-btn>
          <v-spacer />
          <v-checkbox class="mr-3" v-model="autoTranslateEnabled" :label="`Auto Translate ${this.order.countryCode}`"/>
          <v-btn
            :disabled="isSending"
            :loading="isSending"
            color="success"
            @click="sendMail"
          >Send Mail</v-btn
          >
        </template>
        <template v-else>
          <v-btn text @click="cancelAddTemplate">Cancel</v-btn>
          <v-spacer />
          <v-btn
            :loading="deletingTemplate"
            :disabled="savingTemplate || deletingTemplate"
            color="error"
            text
            @click="deleteTemplate"
            v-if="addTemplate && editTemplate"
          >DELETE</v-btn
          >
          <v-btn
            :loading="savingTemplate"
            :disabled="savingTemplate || deletingTemplate"
            color="success"
            @click="saveMailTemplate"
          >Save Template</v-btn
          >
        </template>
      </v-card-actions>
    </v-card>
  </MainDialog>
</template>

<script>
import VueTrix from "vue-trix";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../fb";
import { EventBus, events } from "../../event-bus";
import {convertToDayMonth, currentMonthYear} from "@/utils";
import MainDialog from "@/components/MainDialog";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import {mapActions, mapGetters} from "vuex";
import {domainByShopId, supportNameByShopId} from "@/constants";
import _ from "lodash";
export default {
  name: "MailDialogV2",
  components: {
    MainDialog,
    VueTrix,
  },
  data() {
    return {
      showDialog: false,
      receiverMail: '',
      messageValue: "",
      subjectValue: "",
      isSending: false,
      selectedTemplate: null,
      attachments: [],
      autoTranslateEnabled: false,
      availableVariables: null,
      buttonCountryCode: {
        ES: "SPANISH",
        DE: "GERMAN",
        PL: "POLISH"
      },
      showAvailableVariables: false,
      indexOpenBracelet: null,
      addTemplate: false,
      savingTemplate: false,
      deletingTemplate: false,
      templateName: "",
      editTemplate: null
    };
  },
  created() {
    this.receiverMail = this.order.customer.email
    this.autoTranslateEnabled = this.order.countryCode.toLowerCase() !== "de"
    this.availableVariables = {
      orderName: this.order.orderName,
      firstName: this.order.customer?.firstName,
      carrier: this.getLatestShipment(this.order).carrier,
      trackingCode: this.getLatestShipment(this.order).trackingCode,
      trackingUrl: this.getLatestShipment(this.order).trackingUrl,
      shippingDate: this.getLatestShipment(this.order).shippingDate,
      domain: domainByShopId?.[this.order.shopId]?.[this.order.countryCode],
      supportName: supportNameByShopId?.[this.order.shopId]?.[this.order.countryCode] || "Anna",
      templatePreviewUrl: this.order.finalTemplateThumbnailUrl,
      fulfillDate: convertToDayMonth(this.order.fulfillDate),
      resultsSent: convertToDayMonth(this.order.resultsSentDate)
    };
    this.setTemplate(this.templatesForCategory?.[0] || null)
  },
  computed: {
    ...mapGetters(["mailTemplates"]),
    templatesForCategory() {
      return this.mailTemplates.filter(t => t.category === this.mailCategory)
    }
  },
  props: {
    show: {
      default: false,
    },
    onClose: {
      required: true,
    },
    onSent: {
      required: false,
    },
    waitForSentSuccess: {
      type: Boolean,
      default: true
    },
    mailCategory: {
      type: String,
      default: "ALL"
    },
    order: {
      type: Object,
      required: true,
    }
  },
  watch: {
    messageValue(val) {
      if (!this.addTemplate) return;

      // Use a stack to keep track of unclosed '{'
      const bracePositions = [];
      for (let i = 0; i < val.length; i++) {
        if (val[i] === '{') {
          bracePositions.push(i); // Push the index of '{'
        } else if (val[i] === '}') {
          bracePositions.pop(); // Pop the last '{' index as it is now closed
        }
      }

      // The last item in the stack is the index of the last unclosed '{'
      const lastUnclosedBraceIndex = bracePositions.length > 0 ? bracePositions[bracePositions.length - 1] : -1;

      // Only update if there's an unclosed brace
      if (lastUnclosedBraceIndex !== -1 && !this.showAvailableVariables) {
        this.indexOpenBracelet = lastUnclosedBraceIndex;
        this.showAvailableVariables = true;
      } else if (lastUnclosedBraceIndex === -1) {
        // No unclosed '{' found or all '{' are properly closed
        this.showAvailableVariables = false;
      }

      // Reset `showAvailableVariables` to false if the last character is '}'
      if (val.endsWith('}')) {
        this.showAvailableVariables = false;
      }
    }
  },
  methods: {
    ...mapActions(["addMailTemplate", "deleteMailTemplate"]),
    setTemplate(temp) {
      this.selectedTemplate = temp
      this.messageValue = temp ? this.fillTemplateVariables(temp.message) : "";
      this.subjectValue = temp ? this.fillTemplateVariables(temp.subject) : "";
    },
    showEditTemplate() {
      if (this.selectedTemplate) {
        this.messageValue = this.selectedTemplate.message;
        this.subjectValue = this.selectedTemplate.subject;
        this.templateName = this.selectedTemplate.name;
        this.editTemplate = true;
        this.addTemplate = true;
      }
    },
    showAddNewTemplate() {
      this.messageValue = this.selectedTemplate?.message || this.messageValue;
      this.subjectValue = this.selectedTemplate?.subject || this.subjectValue;
      this.editTemplate = false
      this.addTemplate = true
    },
    async deleteTemplate() {
      this.deletingTemplate = true
      await this.deleteMailTemplate({ uid: this.selectedTemplate.uid })
      this.deletingTemplate = false
      this.setTemplate(null)
      this.addTemplate = false
      this.editTemplate = false
    },
    cancelAddTemplate() {
      this.setTemplate(this.selectedTemplate)
      this.addTemplate = false
      this.editTemplate = false
    },
    async saveMailTemplate() {
      if (this.messageValue && this.subjectValue && this.templateName) {
        this.savingTemplate = true
        const newTemp = {
          message: this.messageValue,
          subject: this.subjectValue,
          name: this.templateName,
          category: this.mailCategory || '',
          uid: this.editTemplate ? this.selectedTemplate.uid : null
        }
        const uid = await this.addMailTemplate(newTemp)
        this.addTemplate = false
        this.savingTemplate = false
        this.setTemplate({...newTemp, uid})
      }

    },
    fillTemplateVariables(value) {
      const valCopy = _.clone(value)
      return valCopy.replace(/\{(\w+)\}/g, (placeholder, key) => {
        // Replace the placeholder with the value from the 'values' object
        // If the key is not found, keep the original placeholder
        return key in this.availableVariables ? this.availableVariables[key] : placeholder;
      });
    },
    selectVariable(variable) {
      const insertIndex = this.indexOpenBracelet + 1
      this.messageValue = this.messageValue.substring(0, insertIndex) + variable + "}" + this.messageValue.substring(insertIndex, this.messageValue.length)
    },
    getLatestShipment(order) {
      console.log(
        order?.fulfillment?.[Object.keys(order.fulfillment)[0]]?.[0]
          ?.fulfillItems?.[0]?.shipment
      );
      const shipment =
        order?.fulfillment?.[Object.keys(order.fulfillment)[0]]?.[0]
          ?.fulfillItems?.[0]?.shipment;

      return {
        carrier: shipment?.carrier,
        trackingCode: shipment?.trackingCode,
        trackingUrl: shipment?.trackingUrl,
        shippingDate: shipment?.shippingDate,
      };
    },
    async sendMail() {
      this.isSending = true;
      if (!this.waitForSentSuccess) {
        this.onClose();
      }
      const attachmentsURLs = [];
      if (this.attachments.length) {
        const storage = getStorage();
        for await (const [index, value] of this.attachments.entries()) {
          const storageRef = ref(
            storage,
            `/mailAttachments/${currentMonthYear}/${
              this.receiverMail.split("@")[0]
            }_${index}.png`
          );
          const uploadResponse = await uploadBytes(storageRef, value, {
            cacheControl: "public,max-age=300",
          });
          attachmentsURLs.push({
            filename: value.name,
            path: await getDownloadURL(uploadResponse.ref),
          });
        }
      }
      const mailToCustomer = httpsCallable(functions, "sendMail");

      const mailData = {
        receiver: this.receiverMail,
        subject: this.subjectValue,
        message: this.messageValue,
        countryCode: this.order.countryCode,
        shopId: this.order.shopId,
        attachments: attachmentsURLs,
        autoTranslate: this.autoTranslateEnabled
      }

      const res = await mailToCustomer(mailData);

      if (res?.data?.status === "error") {
        EventBus.$emit(events.SNACKBAR, {
          message: `Send Mail ${this.subjectValue} failed! ${res.data.message}`,
          type: "error",
        });
      } else {
        if (!!this.onSent) {
          this.onSent({mail: mailData})
        }
        EventBus.$emit(events.SNACKBAR, {
          message: `Send Mail ${this.subjectValue} to Customer by ${this.order.countryCode}-Domain`,
          type: "success",
        });
      }

      if (!!this.waitForSentSuccess) {
        this.onClose();
      }
    },
    async onFileChange(event) {
      if (event.target.files.length) {
        for await (const file of Array.from(event.target.files)) {
          this.attachments.push(file);
        }
      } else {
        this.attachments = null;
      }
    },
  },
};
</script>

<style lang="scss">
.wysiwyg-editor {
  margin-top: 12px;
  button {
    background-color: white;
    opacity: 0.9;
  }
}

.messages-chip-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 6px;
}

.messages-chip--highlight {
  border: 1px solid #beffa1 !important;
}

.availableVariablesHelper {
  display: flex;
  gap: 6px;
  background: beige;
  border-radius: 5px;
  flex-direction: column;
  height: 100px;
  overflow-y: scroll;
  margin: 12px 0;
  padding: 6px;

  > div {
    cursor: pointer;

    &:hover {
      font-weight: bold;
    }
  }
}
</style>
