<template>
  <v-card>
    <v-card-title>
      <a class="mr-3" @click="() => openOrder(rating.orderId)">{{
        rating.orderName
      }}</a>
      <v-icon :color="rating.satisfied ? 'success' : 'error'">{{
        rating.satisfied ? "mdi-thumb-up" : "mdi-thumb-down"
      }}</v-icon>
      <v-spacer />
      <OrderNoteTooltip :task="rating.task" />
    </v-card-title>
    <v-card-subtitle> by {{ artistName }}, {{ timeAgo }} </v-card-subtitle>
    <v-card-text class="card-content">
      <v-img
        contain
        height="300px"
        v-if="!expandComment"
        :src="rating.mockupImage || rating.resultThumbnailUrl"
      />
      <div
        @click="
          () => {
            expandComment = !expandComment;
          }
        "
        :class="{ 'rating-comment--full': expandComment }"
        class="rating-comment"
      >
        {{ rating.comment }}
      </div>
    </v-card-text>
    <v-card-actions>
      <template v-if="!rating.satisfied">
        <v-tooltip open-delay="200" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-bind="attrs"
              v-on="on"
              @click="openMailDialog"
              color="success"
              outlined
            >
              <v-icon>mdi-email</v-icon>
            </v-btn>
          </template>
          <span>Contact Customer</span>
        </v-tooltip>
        <v-tooltip open-delay="200" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-3"
              small
              v-bind="attrs"
              v-on="on"
              @click="searchOrderInMails"
              color="success"
              outlined
            >
              <v-icon>mdi-email-search-outline</v-icon>
            </v-btn>
          </template>
          <span>Search in mails</span>
        </v-tooltip>
        <v-tooltip color="error" open-delay="200" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="pushedBack"
              class="ml-3"
              small
              v-bind="attrs"
              v-on="on"
              @click="openPushBackDialog"
              color="error"
            >
              <v-icon>mdi-backspace</v-icon>
            </v-btn>
          </template>
          <span>Push Task Back</span>
        </v-tooltip>
        <v-tooltip open-delay="200" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              class="ml-3"
              v-bind="attrs"
              v-on="on"
              @click="openReassignDialog"
              color="warning"
              outlined
            >
              <v-icon>mdi-account-reactivate</v-icon>
            </v-btn>
          </template>
          <span>Reassign Task (To Pay)</span>
        </v-tooltip>
        <v-spacer />
      </template>
      <v-btn
        :width="rating.satisfied ? '100%' : ''"
        :disabled="rating.shared || isOwnFeedback"
        @click="
          () => {
            showShareDialog = true;
          }
        "
        small
        color="success"
        >{{ ratingBtnText }}</v-btn
      >
    </v-card-actions>
    <PushTaskBackDialog
      v-if="showPushTaskBackDialog"
      :status="['DRAW', 'TEMPLATING']"
      :show="showPushTaskBackDialog"
      :task-uid="rating.taskUid"
      :on-close="closePushBackDialog"
      :on-push-back="markAsPushedBack"
    />
    <ReassignDialog v-if="showReassignDialog" :show="showReassignDialog" :task="rating.task" :on-close="closeReassignDialog" :persistent="false"/>
    <OrderMailDialog
      v-if="showMailDialog"
      :show="showMailDialog"
      :on-close="closeMailDialog"
      :orderId="rating.orderId"
      :mail-template-type="mailTemplateType"
    />
    <ShareRatingDialog
      @shared="onShared"
      :close="
        () => {
          showShareDialog = false;
        }
      "
      :show="showShareDialog"
      :rating="rating"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ShareRatingDialog from "@/components/AllRatings/ShareRatingDialog";
import {getDateFromNow, openOrderModal} from "@/utils";
import PushTaskBackDialog from "@/components/PushTaskBackDialog";
import OrderMailDialog from "@/components/OrderMailDialog";
import { mailTemplateTypes} from "@/content/mails";
import ReassignDialog from "@/components/AllRatings/ReassignDialog";
import OrderNoteTooltip from "@/components/OrderNoteTooltip";

export default {
  name: "RatingCardAdmin",
  components: {OrderNoteTooltip, ReassignDialog, OrderMailDialog, PushTaskBackDialog, ShareRatingDialog },
  data() {
    return {
      artistName: "",
      expandComment: false,
      showShareDialog: false,
      showPushTaskBackDialog: false,
      showMailDialog: false,
      pushedBack: false,
      showReassignDialog: false,
      showOrderNoteDialog: false,
    };
  },
  props: {
    rating: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["userNameIdMap", "currentUser"]),
    mailTemplateType() {
      return mailTemplateTypes.REVISE;
    },
    ratingBtnText() {
      return this.rating.shared ? `Shared` : `Share`;
    },
    isOwnFeedback() {
      return this.rating.artistId === this.currentUser.uid;
    },
    timeAgo() {
      return getDateFromNow(this.rating.timestamp);
    },
  },
  created() {
    const user = this.userNameIdMap.find(
      (user) => user.uid === this.rating.artistId
    );
    this.artistName = user?.username || "unknown";
  },
  methods: {
    openOrder(uid) {
      openOrderModal(uid)
    },
    onShared() {
      this.$emit("feedbackShared", this.rating.taskUid);
    },
    openPushBackDialog() {
      this.showPushTaskBackDialog = true;
    },
    closePushBackDialog() {
      this.showPushTaskBackDialog = false;
    },
    openMailDialog() {
      this.showMailDialog = true;
    },
    closeMailDialog() {
      this.showMailDialog = false;
    },
    markAsPushedBack() {
      this.pushedBack = true
    },
    openReassignDialog() {
      this.showReassignDialog = true
    },
    closeReassignDialog() {
      this.showReassignDialog = false
    },
    searchOrderInMails(orderName) {
      window.open(
        `https://mail.google.com/mail/u/?authuser=lisa@liebespferd.com&tab=wm#search/%23${
          this.rating.orderName.split("#")[1]
        }`,
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.card-content {
  height: 380px;
}
.rating-comment {
  cursor: pointer;
  font-size: 21px;
  line-height: 1.2em; /* Adjust this based on your design needs */
  max-height: 2.4em; /* 2 times the line-height, to display 2 lines of text */
  min-height: 2.4em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

  &--full {
    max-height: 100%;
    overflow: scroll;
    display: -webkit-box;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
    text-overflow: unset;
  }
}
</style>
