<template>
  <div>
    <v-card
      @contextmenu="onRightClick"
      color="primary"
      class="card"
      min-width="200"
    >
      <div class="card-header-function">
        <v-btn
          @click="openTask"
          color="primary"
          dark
          rounded
          link
          v-if="isAdmin"
          class="card-header-button"
        >
          {{ task.orderName }}
          <v-icon x-small v-if="isAdmin" class="ml-3">mdi-open-in-new</v-icon>
        </v-btn>
        <FeedbackTooltip
          v-if="task.feedback && isAdmin"
          :feedback="task.feedback"
        />
        <OrderNoteTooltip v-if="isAdmin" :task="task"/>
        <SupportTooltip v-if="isAdmin" :order-id="task.orderId" />
        <TimeIndicator :creation-date="task.createdAt"/>
        <v-btn
          @click="downloadFiles"
          :color="downloadProgress === 100 ? 'success' : 'primary'"
          dark
          small
          fab
          class="mark-button"
        >
          <v-progress-circular width="3" :value="downloadProgress">
            <v-badge color="green" :content="1">
              <v-icon>mdi-download</v-icon>
            </v-badge>
          </v-progress-circular>
        </v-btn>
      </div>
      <v-card-text class="image-container">
        <ExpressLayover :task="task"/>
        <v-img
          height="500px"
          contain
          :src="customImageUri[1] || task.finalTemplateThumbnailUrl"
          :lazy-src="customImageUri[1] || task.finalTemplateThumbnailUrl"
          class="image"
          @click="
            openFullSizeImage({
              image: customImageUri[1] || task.finalTemplate,
              thumb: customImageUri[1] || task.finalTemplateThumbnailUrl,
            })
          "
        />
        <div class="task-product-name">
          {{ task.productType.name }}<br/>
          {{ task?.customPrintVariantName || task.printVariantName }}
          {{task.express ? ' (Express)' : ''}}
        </div>
        <div class="revision-reason-container" v-if="task.revisionReason">
          From Revision: {{ task.revisionReason }}
        </div>
        <div
          v-if="!task.productType.isPreview"
          class="task-template"
          @click="
            openFullSizeImage({
              image: orgTemplate,
              thumb: orgTemplateThumb,
            })
          "
        >
          <v-img contain :src="orgTemplateThumb"/>
        </div>
        <div
          class="task-original-image task-original-image--1"
          @click="
            openFullSizeImage({
              image: task.image,
              thumb: task.thumb,
            })
          "
        >
          <v-img contain :src="task.thumb"/>
        </div>
        <div
          class="task-original-image task-original-image--2"
          @click="
            openFullSizeImage({
              image: task.image2,
              thumb: task.image2Thumb,
            })
          "
        >
          <v-img contain :src="task.image2Thumb"/>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-tooltip color="error" open-delay="200" right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="showPushTaskBackDialog = true"
              color="error"
            >
              <v-icon>mdi-backspace</v-icon>
            </v-btn>
          </template>
          <span>Push Task Back</span>
        </v-tooltip>
        <v-file-input
          class="mx-3"
          :prepend-icon="null"
          @change.native="(e) => onFileChange(e, 1)"
          @click:clear="onFileChange(null, 1)"
          dark
          label="Custom Template"
          outlined
          dense
          hide-details
        ></v-file-input>
        <v-tooltip color="success" open-delay="200" left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :loading="isAccepting"
              :disabled="isAccepting"
              @click="accept"
              color="success"
            >
              <v-icon>mdi-hand-okay</v-icon>
            </v-btn>
          </template>
          <span>Accept Task</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
    <ListMenu :show="showMenu" :x="x" :y="y" :list-items="menuItems"/>
    <PushTaskBackDialog
      v-if="showPushTaskBackDialog"
      :show="showPushTaskBackDialog"
      :on-close="
        () => {
          showPushTaskBackDialog = false;
        }
      "
      :task-uid="task.uid"
      :status="['TEMPLATING', 'DRAW']"
    />
    <ImageDialog
      :show="showSelectedTaskImage"
      :on-close="() => (showSelectedTaskImage = false)"
      :image="selectedFullscreenImage.img"
      :image-thumb="selectedFullscreenImage.thumb"
    />
    <OrderMailDialog
      v-if="showMailDialog"
      :show="showMailDialog"
      :on-close="() => (this.showMailDialog = false)"
      :orderId="task.orderId.toString()"
      :mail-template-type="mailTemplateType"
      :wait-for-sent-success="false"
    />
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {downloadImageFromUrl, openOrderModal} from "@/utils";
import PushTaskBackDialog from "@/components/PushTaskBackDialog";
import OrderNoteTooltip from "@/components/OrderNoteTooltip";
import ListMenu from "@/components/ListMenu";
import TimeIndicator from "@/components/TaskCards/components/TimeIndicator";
import {taskStatus} from "@/constants";
import OrderMailDialog from "@/components/OrderMailDialog";
import {mailTemplateTypes} from "@/content/mails";
import ImageDialog from "@/components/ImageDialog";
import FeedbackTooltip from "@/components/FeedbackTooltip";
import {doc, setDoc} from "firebase/firestore";
import {db} from "../../../fb";
import {EventBus, events} from "../../../event-bus";
import ExpressLayover from "@/components/TaskCards/ExpressLayover";

export default {
  name: "ReviewTemplatingTaskCard",
  components: {
    ExpressLayover,
    FeedbackTooltip,
    ImageDialog,
    OrderMailDialog,
    TimeIndicator,
    ListMenu,
    OrderNoteTooltip,
    PushTaskBackDialog,
  },
  data() {
    return {
      orientation: null,
      isDownloaded: false,
      showPushTaskBackDialog: false,
      showMailDialog: false,
      x: 0,
      y: 0,
      showMenu: false,
      isAccepting: false,
      isAccepted: false,
      selectedFullscreenImage: {
        img: "",
        thumb: "",
      },
      showSelectedTaskImage: false,
      customImage: {
        1: null,
      },
      customImageUri: {
        1: "",
      },
      downloadProgress: 0,
      menuItems: [
        {
          icon: "mdi-thumbs-up-down",
          onClick: this.resolveThumbUpDown,
          label: "Resolve Feedback",
          hide: !this.task.feedback
        },
        {
          icon: "mdi-download",
          onClick: () => this.downloadOriginalImage(1),
          label:
            this.task.numberOfSubTasks > 1
              ? "Download Original Image Left"
              : "Download Original Image",
        },
        {
          icon: "mdi-download",
          onClick: () => this.downloadOriginalImage(2),
          label: "Download Original Image Right",
          hide: !this.task.numberOfSubTasks < 2,
        },
        {
          icon: "mdi-download",
          onClick: () => this.downloadResultImage(1),
          label:
            this.task.numberOfSubTasks > 1
              ? "Download Result Image Left"
              : "Download Result Image",
        },
        {
          icon: "mdi-download",
          onClick: () => this.downloadResultImage(2),
          label: "Download Result Image Right",
          hide: this.task.numberOfSubTasks < 2,
        },
        {
          icon: "mdi-download",
          onClick: this.downloadTemplate,
          label: "Download Template",
        },
        {
          icon: "mdi-download",
          onClick: this.downloadFinalTemplate,
          label: "Download Final Template",
          hide: this.task.reviewType === taskStatus.DRAW,
        },
        {
          icon: "mdi-email-outline",
          onClick: () => {
            this.showMailDialog = true;
          },
          label: "Send Mail",
          hide: this.task.reviewType === taskStatus.DRAW,
        },
        {
          icon: "mdi-email-search-outline",
          onClick: this.searchOrderInMails,
          label: "Search In Mails",
        },
        {
          icon: "mdi-alpha-t-box",
          onClick: this.openInTeeInBlue,
          label: "Open In TeeInBlue",
        },
      ],
    };
  },
  props: {
    task: {
      required: true,
    },
  },
  created() {
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    mailTemplateType() {
      return mailTemplateTypes.REVIEW;
    },
    orgTemplateThumb() {
      const adjustedTemplateURL = this.task.templateThumb.replace('teeinblue-cdn.nyc3.digitaloceanspaces.com', 'cdn.teeinblue.com');
      return adjustedTemplateURL
    },
    orgTemplate() {
      const adjustedTemplateURL = this.task.template.replace('teeinblue-cdn.nyc3.digitaloceanspaces.com', 'cdn.teeinblue.com');
      return adjustedTemplateURL
    }
  },
  methods: {
    ...mapActions(["acceptTaskResult"]),
    openTask() {
      if (this.isAdmin) {
        openOrderModal(this.task.orderId)
      }
    },
    onFileChange(event, imageIndex) {
      const file = event?.target?.files?.[0] || null;
      this.customImage[imageIndex] = file;
      this.customImageUri[imageIndex] = file ? URL.createObjectURL(file) : "";

      const img = new Image();
      img.onload = () => {
        this.orientation = img.width > img.height ? 'landscape' : 'portrait';
        console.log('Orientation: ' + this.orientation); // Optional: for checking
      };
      img.src = this.customImageUri[imageIndex];
    },
    openFullSizeImage({image, thumb}) {
      this.selectedFullscreenImage = {
        img: image,
        thumb: thumb,
      };
      this.showSelectedTaskImage = true;
    },
    accept() {
      this.isAccepting = true;
      this.acceptTaskResult({
        task: this.task,
        customImage: this.customImage || null,
        orientation: this.customImage ? this.orientation : null,
        callback: () => {
          this.isAccepting = false;
          this.isAccepted = true;
        },
      });
    },
    onRightClick(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    downloadFiles() {
      this.downloadResultImage(1);
      if (this.task.numberOfSubTasks > 1) {
        this.downloadResultImage(2);
      }
      this.downloadTemplate();
    },
    downloadResultImage(imageIndex) {
      const imageToDownload =
        imageIndex === 1 ? this.task.result : this.task.result2;
      const fileAppendix =
        this.task.numberOfSubTasks > 1
          ? imageIndex === 1
            ? "_LEFT"
            : "_RIGHT"
          : "";
      const fileName = `${this.task.orderName}${
        fileAppendix ? "_" + fileAppendix : ""
      }`;
      downloadImageFromUrl(
        imageToDownload,
        fileName,
        (progress) => (this.downloadProgress = progress === 100 ? 0 : progress)
      );
    },
    downloadFinalTemplate() {
      downloadImageFromUrl(
        this.task.finalTemplate,
        `${this.task.orderName}_temp`,
        (progress) => (this.downloadProgress = progress === 100 ? 0 : progress),
        "jpg"
      );
    },
    downloadOriginalImage(imageIndex) {
      const imageToDownload =
        imageIndex === 1 ? this.task.image : this.task.image2;
      const fileName = `${this.task.uid}_${
        imageIndex === 1 ? "_LEFT" : "_RIGHT"
      }`;
      downloadImageFromUrl(
        imageToDownload,
        fileName,
        (progress) => (this.downloadProgress = progress === 100 ? 0 : progress)
      );
    },
    downloadTemplate() {
      const adjustedTemplateURL = this.task.template.replace('teeinblue-cdn.nyc3.digitaloceanspaces.com', 'cdn.teeinblue.com');
      downloadImageFromUrl(
        adjustedTemplateURL,
        `${this.task.orderName}_temp`,
        (progress) => (this.downloadProgress = progress === 100 ? 0 : progress),
        "jpg"
      );
    },
    openInTeeInBlue() {
      const url =
        "https://portal.teeinblue.com/orders/?s=" +
        encodeURIComponent(this.task.orderName);
      window.open(url, "_blank");
    },
    searchOrderInMails() {
      window.open(
        `https://mail.google.com/mail/u/?authuser=lisa@liebespferd.com&tab=wm#search/%23${
          this.task.orderName.split("#")[1]
        }`,
        "_blank"
      );
    },
    async resolveThumbUpDown() {
      await setDoc(
        doc(db, `tasks`, this.task.uid),
        { feedback: null },
        {
          merge: true,
        }
      );
      EventBus.$emit(events.SNACKBAR, {type: 'success', message: 'Resolved Feedback'})
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  overflow: hidden;

  .v-card__text {
    padding: 0;
  }
}

.card-header-function {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .v-btn {
    margin: 12px;
  }
}

.image-container {
  position: relative;
}

.image {
  z-index: 1;
}

.task-original-image {
  width: 80px;
  height: fit-content;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  z-index: 2;
  transform-origin: bottom left;
  transform: scale(1);
  transition: all 0.5s;

  &--1 {
    left: 0;
    z-index: 3;
  }

  &--2 {
    left: 90px;
  }

  &:hover {
    transform: scale(3);
  }
}

.task-template {
  width: 80px;
  height: fit-content;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  transform-origin: bottom right;
  transform: scale(1);
  transition: all 0.5s;

  &:hover {
    transform: scale(4);
  }
}

.revision-reason-container {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  display: flex;
  z-index: 2;
  background: rgba(162, 0, 0, 0.74);
  color: #ffffff;
  padding: 5px 10px;
}

.rotate-90 {
  transform: rotate(-90deg);
}

.task-product-name {
  padding: 6px;
  border-radius: 5px;
  position: absolute;
  background: rgba(0, 0, 0, 0.87);
  max-width: 50%;
  color: white;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
</style>
