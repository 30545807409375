var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"card",attrs:{"color":"primary","min-width":"200"},on:{"contextmenu":_vm.onRightClick}},[_c('div',{staticClass:"card-header-function"},[(_vm.isAdmin)?_c('v-btn',{staticClass:"card-header-button",attrs:{"color":"primary","dark":"","rounded":"","link":""},on:{"click":_vm.openTask}},[_vm._v(" "+_vm._s(_vm.task.orderName)+" "),(_vm.isAdmin)?_c('v-icon',{staticClass:"ml-3",attrs:{"x-small":""}},[_vm._v("mdi-open-in-new")]):_vm._e()],1):_vm._e(),(_vm.task.feedback && _vm.isAdmin)?_c('FeedbackTooltip',{attrs:{"feedback":_vm.task.feedback}}):_vm._e(),(_vm.isAdmin)?_c('OrderNoteTooltip',{attrs:{"task":_vm.task}}):_vm._e(),(_vm.isAdmin)?_c('SupportTooltip',{attrs:{"order-id":_vm.task.orderId}}):_vm._e(),_c('TimeIndicator',{attrs:{"creation-date":_vm.task.createdAt}}),_c('v-btn',{staticClass:"mark-button",attrs:{"color":_vm.downloadProgress === 100 ? 'success' : 'primary',"dark":"","small":"","fab":""},on:{"click":_vm.downloadFiles}},[_c('v-progress-circular',{attrs:{"width":"3","value":_vm.downloadProgress}},[_c('v-badge',{attrs:{"color":"green","content":1}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1)],1)],1),_c('v-card-text',{staticClass:"image-container"},[_c('ExpressLayover',{attrs:{"task":_vm.task}}),_c('v-img',{staticClass:"image",attrs:{"height":"500px","contain":"","src":_vm.customImageUri[1] || _vm.task.finalTemplateThumbnailUrl,"lazy-src":_vm.customImageUri[1] || _vm.task.finalTemplateThumbnailUrl},on:{"click":function($event){return _vm.openFullSizeImage({
            image: _vm.customImageUri[1] || _vm.task.finalTemplate,
            thumb: _vm.customImageUri[1] || _vm.task.finalTemplateThumbnailUrl,
          })}}}),_c('div',{staticClass:"task-product-name"},[_vm._v(" "+_vm._s(_vm.task.productType.name)),_c('br'),_vm._v(" "+_vm._s(_vm.task?.customPrintVariantName || _vm.task.printVariantName)+" "+_vm._s(_vm.task.express ? ' (Express)' : '')+" ")]),(_vm.task.revisionReason)?_c('div',{staticClass:"revision-reason-container"},[_vm._v(" From Revision: "+_vm._s(_vm.task.revisionReason)+" ")]):_vm._e(),(!_vm.task.productType.isPreview)?_c('div',{staticClass:"task-template",on:{"click":function($event){return _vm.openFullSizeImage({
            image: _vm.orgTemplate,
            thumb: _vm.orgTemplateThumb,
          })}}},[_c('v-img',{attrs:{"contain":"","src":_vm.orgTemplateThumb}})],1):_vm._e(),_c('div',{staticClass:"task-original-image task-original-image--1",on:{"click":function($event){return _vm.openFullSizeImage({
            image: _vm.task.image,
            thumb: _vm.task.thumb,
          })}}},[_c('v-img',{attrs:{"contain":"","src":_vm.task.thumb}})],1),_c('div',{staticClass:"task-original-image task-original-image--2",on:{"click":function($event){return _vm.openFullSizeImage({
            image: _vm.task.image2,
            thumb: _vm.task.image2Thumb,
          })}}},[_c('v-img',{attrs:{"contain":"","src":_vm.task.image2Thumb}})],1)],1),_c('v-card-actions',[_c('v-tooltip',{attrs:{"color":"error","open-delay":"200","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error"},on:{"click":function($event){_vm.showPushTaskBackDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-backspace")])],1)]}}])},[_c('span',[_vm._v("Push Task Back")])]),_c('v-file-input',{staticClass:"mx-3",attrs:{"prepend-icon":null,"dark":"","label":"Custom Template","outlined":"","dense":"","hide-details":""},on:{"click:clear":function($event){return _vm.onFileChange(null, 1)}},nativeOn:{"change":function($event){return ((e) => _vm.onFileChange(e, 1)).apply(null, arguments)}}}),_c('v-tooltip',{attrs:{"color":"success","open-delay":"200","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.isAccepting,"disabled":_vm.isAccepting,"color":"success"},on:{"click":_vm.accept}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-hand-okay")])],1)]}}])},[_c('span',[_vm._v("Accept Task")])])],1)],1),_c('ListMenu',{attrs:{"show":_vm.showMenu,"x":_vm.x,"y":_vm.y,"list-items":_vm.menuItems}}),(_vm.showPushTaskBackDialog)?_c('PushTaskBackDialog',{attrs:{"show":_vm.showPushTaskBackDialog,"on-close":() => {
        _vm.showPushTaskBackDialog = false;
      },"task-uid":_vm.task.uid,"status":['TEMPLATING', 'DRAW']}}):_vm._e(),_c('ImageDialog',{attrs:{"show":_vm.showSelectedTaskImage,"on-close":() => (_vm.showSelectedTaskImage = false),"image":_vm.selectedFullscreenImage.img,"image-thumb":_vm.selectedFullscreenImage.thumb}}),(_vm.showMailDialog)?_c('OrderMailDialog',{attrs:{"show":_vm.showMailDialog,"on-close":() => (this.showMailDialog = false),"orderId":_vm.task.orderId.toString(),"mail-template-type":_vm.mailTemplateType,"wait-for-sent-success":false}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }