<template>
  <div class="statistics-view">
    <StatisticsHeader />
    <div class="d-flex justify-center">
      <StateDisplay
        class="mt-12"
        v-if="statisticLoading || statisticError"
        :loading="statisticLoading"
        :error="statisticError"
        :no-results="statistic.shops.length === 0"
      />
      <div v-else>
        <v-row>
          <v-col cols="12">
            <div class="last-update-text">
              {{lastUpdateText}}
              <v-btn class="ml-1" x-small :loading="statisticRefreshing" icon @click="refreshStatistic">
                <v-icon x-small>mdi-refresh</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <StatisticOverview :data="statistic" />
        <StatisticByShops :class="$vuetify.breakpoint.mobile ? '': 'mt-3'" :data="statistic.shops" />
      </div>
    </div>
  </div>
</template>

<script>
import StatisticOverview from "@/components/Statistics/StatisticOverview/StatisticOverview";
import StateDisplay from "@/components/StateDisplay";
import { mapActions, mapGetters, mapMutations } from "vuex";
import StatisticsHeader from "@/components/Statistics/StatisticsHeader";

import dayjs from "dayjs";
import StatisticByShops from "@/components/Statistics/StatisticByShops/StatisticByShops";
import {getDateFromNow} from "@/utils";
const timezone = require("dayjs/plugin/timezone"); // Import timezone plugin
const utc = require('dayjs/plugin/utc'); // Import UTC plugin

dayjs.extend(utc); // Enable UTC plugin
dayjs.extend(timezone); // Enable timezone plugin

export default {
  name: "StatisticsView",
  components: {StatisticByShops, StatisticsHeader, StateDisplay, StatisticOverview },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "statisticLoading",
      "statisticError",
      "statisticErrorMessage",
      "statisticRange",
      "statistic",
      "statisticRefreshing"
    ]),
    lastUpdateText() {
      return this.statistic?.information?.lastUpdate ? `Last Update: ${getDateFromNow(this.statistic.information.lastUpdate)}` : null
    },
  },
  mounted() {
    this.setCurrentDate();
    this.loadStatistic();
  },
  methods: {
    ...mapActions(["loadStatistic", "refreshStatistic"]),
    ...mapMutations(["setStatisticRange"]),
    setCurrentDate() {
      const currentLocalizedDate = dayjs().tz("Europe/Berlin", true);

      this.setStatisticRange({
        min: currentLocalizedDate.startOf("day").format(),
        max: currentLocalizedDate.endOf("day").format(),
      });
      // this.setStatisticRange({
      //   min: "2024-06-05T00:00:00+02:00",
      //   max: "2024-06-05T00:00:02+02:00",
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics-view {
  width: 100%;
}

.last-update-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: lighter;
  font-size: 16px;

  @include md() {
    font-size: 12px;
  }
}
</style>
