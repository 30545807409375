export const checkIsValidOrder = (order) => {
  let isValid = true;

  // Check if addressPrimary ends with a digit or digit followed by one char
  isValid =
    isValid && /\d[A-Za-z]?$/.test(order.shippingAddress.addressPrimary);

  // Check if addressSecondary is not defined or empty
  isValid =
    isValid &&
    (!order.shippingAddress.addressSecondary ||
      order.shippingAddress.addressSecondary.trim() === "");

  return isValid;
};
