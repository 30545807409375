<template>
  <v-simple-table :height="height">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Task ID</th>
          <th class="text-left">Price in {{ payment.currency }}</th>
          <th class="text-left" v-if="currentUser?.managedUsers?.length">
            Completed By
          </th>
          <th class="text-left">Completion Date</th>
        </tr>
      </thead>
      <tbody v-if="payment?.unpaidTasks.length">
        <tr v-for="payment in payment?.unpaidTasks" :key="payment.compledDate">
          <td>{{ payment.completedTask }}</td>
          <td>
            {{ payment.price ? payment.price : "-" }}
          </td>
          <td v-if="currentUser?.managedUsers?.length">
            {{ payment.completedBy }}
          </td>
          <td>
            {{ new Date(payment.completedDate).toDateString() }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <h1 style="text-align: center">No Task done yet.</h1>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UnpaidTasksList",
  props: {
    payment: {
      required: true,
    },
    height: {
      default: 400
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>

<style scoped></style>
